import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/home",
    redirect: "/"
  },
  {
    path: "/article",
    redirect: "/"
  },
  {
    path: "/article/:permalink",
    name: "Article",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/Article.vue")
  },
  {
    path: "/products",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products.vue")
  },
  {
    path: "/products/:short",
    name: "Product",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Product.vue")
  },
  {
    path: "/document/:short",
    name: "Document",
    component: () =>
      import(/* webpackChunkName: "documents" */ "../views/Document.vue")
  },
  {
    path: "/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "support" */ "../views/Support.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/dashboard/Dashboard.vue")
  },
  {
    path: "/request/",
    redirect: "/"
  },
  {
    path: "/profile",
    name: "User Profile",
    component: () =>
      import(
        /* webpackChunkName: "user" */ "../Druware.Web.Shared/views/UserProfile.vue"
      )
  },
  {
    path: "/request/:uid",
    name: "Request",
    component: () =>
      import(
        /* webpackChunkName: "user" */ "../Druware.Web.Shared/views/RequestHandler.vue"
      )
  },
  {
    path: "/forgotten",
    name: "Forgotten",
    component: () =>
      import(
        /* webpackChunkName: "user" */ "../Druware.Web.Shared/views/Forgotten.vue"
      )
  },
  // Admin Tools
  {
    path: "/admin/",
    name: "Admin Tools",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/tools/Admin.vue")
  },
  // Asset Management
  {
    path: "/admin/asset/type",
    name: "Asset Type Management",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/assets/types/AssetTypeList.vue"
      )
  },
  {
    path: "/admin/asset/new",
    name: "Asset Management - Add",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/assets/AssetEdit.vue"
      )
  },
  {
    path: "/admin/asset/type/new",
    name: "Asset Type Management - Add",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/assets/types/AssetTypeEdit.vue"
      )
  },
  {
    path: "/admin/asset/type/:typeId",
    name: "Asset Type Management - Edit",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/assets/types/AssetTypeEdit.vue"
      )
  },
  {
    path: "/admin/asset",
    name: "Asset Management",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/assets/AssetList.vue"
      )
  },
  {
    path: "/admin/asset/:fileName",
    name: "Asset Management - Edit",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/assets/AssetEdit.vue"
      )
  },
  {
    path: "/admin/tag/",
    name: "Tag Management",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/tools/tags/TagList.vue")
  },
  // User Management
  {
    path: "/admin/user",
    name: "User Management",
    component: () =>
      import(
        /* webpackChunkName: "admin-usermanagement" */ "../views/tools/users/UserList.vue"
      )
  },
  // Session Management
  {
    path: "/admin/sessions",
    name: "Session Management",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/sessions/SessionList.vue"
      )
  },
  {
    path: "/admin/news",
    name: "News Management",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/news/ArticleList.vue"
      )
  },
  {
    path: "/admin/news/new",
    name: "News Management - Add",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/news/ArticleEdit.vue"
      )
  },
  {
    path: "/admin/news/:permalink",
    name: "News Management - Edit",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/news/ArticleEdit.vue"
      )
  },
  {
    path: "/admin/products",
    name: "Product Management",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/products/ProductList.vue"
      )
  },
  {
    path: "/admin/products/edit",
    name: "Product Management - Add",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/products/ProductEdit.vue"
      )
  },
  {
    path: "/admin/products/edit/:short",
    name: "Product Management - Edit",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/tools/products/ProductEdit.vue"
      )
  },

  // 404...

  {
    path: "*",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "errors" */ "../Druware.Web.Shared/views/404.vue"
      )
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
