<template>
  <div id="view-document-detail" class="p-2">
    <div class="content">
      <div class="title is-5">
        {{ item != null ? item.title : "" }}
      </div>
      <span v-if="item != null" v-html="compileMarkdown(item.body)"></span>
    </div>
  </div>
</template>

<script>
import Marked from "marked";
import API from "@/Druware.Web.Shared/api/api";

const url = "/api/document/";

export default {
  name: "view.document",
  components: {},
  data: function() {
    return {
      session: {},
      item: null
    };
  },
  methods: {
    compileMarkdown(str) {
      return str == null ? null : Marked(str.replaceAll("\\n", "\n"));
    }
  },
  created() {
    this.session = this.$attrs.session;
    API.get(
      url,
      this,
      this.$route.params.short,
      function(obj, data) {
        obj.item = data;
        window.document.title =
          "Druware - " + (obj.item && obj.item.title ? obj.item.title : "");
      },
      function(obj, err) {
        obj.$buefy.toast.open({
          duration: 5000,
          message: err,
          position: "is-bottom",
          type: "is-danger"
        });
      }
    );
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#view-article-detail {
  .left {
    text-align: left;
  }

  .icon-count {
    width: 48px;
  }
}
</style>
